import { Flex, Icon } from '@knapsack/toby';
import { useKsNav } from '@/utils/routes';
import Link from 'next/link';
import {
  secondaryNavHeader,
  secondaryNavHeaderLink,
  secondaryNavHeaderText,
} from './secondary-nav.css';
import { useActiveTopNavItem } from '../hooks';

export const SecondaryNavHeader = () => {
  const activeTopNavItem = useActiveTopNavItem();

  const { createUrl } = useKsNav();

  return (
    <Flex className={secondaryNavHeader} align="center" gap="small">
      {activeTopNavItem?.minRoleNeeded && <Icon symbol="locked" size="small" />}
      <h3 className={secondaryNavHeaderText}>
        {activeTopNavItem?.path ? (
          <Link
            className={secondaryNavHeaderLink}
            href={createUrl(activeTopNavItem.path)}
          >
            {activeTopNavItem?.name}
          </Link>
        ) : (
          activeTopNavItem?.name
        )}
      </h3>
    </Flex>
  );
};

export default SecondaryNavHeader;
