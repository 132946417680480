import {
  NavCustomizationConfig,
  PageElementNames,
  PrimaryNavCustomizationConfig,
  SecondaryNavCustomizationConfig,
  TypographyConfig,
  TypographyLinkConfig,
} from '@knapsack/types';

const themeVarRoot = '--knapsack-custom-theme';
type ThemeVarName = `var(--knapsack-custom-theme--${string}--${string})`;

type TypographyVars = Record<
  Exclude<PageElementNames, 'link'>,
  {
    [key in keyof TypographyConfig]: ThemeVarName;
  }
>;
type TypographyLinkVar = Record<
  'link',
  {
    [key in keyof TypographyLinkConfig]: ThemeVarName;
  }
>;

type PrimaryNavThemeVars = Record<
  'primaryNav',
  {
    [key in keyof PrimaryNavCustomizationConfig]: ThemeVarName;
  }
>;

type SecondaryNavThemeVars = Record<
  'secondaryNav',
  {
    [key in keyof SecondaryNavCustomizationConfig]: ThemeVarName;
  }
>;

type TabsThemeVars = Record<
  'tabs',
  {
    [key in keyof NavCustomizationConfig]: ThemeVarName;
  }
>;

type ThemeVarNames = TypographyVars &
  TypographyLinkVar &
  PrimaryNavThemeVars &
  SecondaryNavThemeVars &
  TabsThemeVars;

/**
 * IMPORTANT: DOES NOT RETURN THE VARIABLE VALUE, ONLY THE VARIABLE NAME.
 * @
 * A terrible hard coded list of the CSS variable names that get generated.
 * This is a stopgap to provide a constant list to the UI so we don't have to
 * write out the over complicated variable names where we use them.
 *
 * @example
 * workspaceThemeVars.bodyText.fontFamily = 'var(--knapsack-custom-theme--body-text--font-family)'
 */
export const workspaceThemeVars: ThemeVarNames = {
  bodyText: {
    fontFamily: `var(${themeVarRoot}--body-text--font-family)`,
    fontSize: `var(${themeVarRoot}--body-text--font-size)`,
    fontStretch: `var(${themeVarRoot}--body-text--font-stretch)`,
    fontWeight: `var(${themeVarRoot}--body-text--font-weight)`,
    letterSpacing: `var(${themeVarRoot}--body-text--letter-spacing)`,
    lineHeight: `var(${themeVarRoot}--body-text--line-height)`,
    textColor: `var(${themeVarRoot}--body-text--text-color)`,
    textTransform: `var(${themeVarRoot}--body-text--text-transform)`,
  },
  headingXLarge: {
    fontFamily: `var(${themeVarRoot}--heading-x-large--font-family)`,
    fontSize: `var(${themeVarRoot}--heading-x-large--font-size)`,
    fontStretch: `var(${themeVarRoot}--heading-x-large--font-stretch)`,
    fontWeight: `var(${themeVarRoot}--heading-x-large--font-weight)`,
    letterSpacing: `var(${themeVarRoot}--heading-x-large--letter-spacing)`,
    lineHeight: `var(${themeVarRoot}--heading-x-large--line-height)`,
    textColor: `var(${themeVarRoot}--heading-x-large--text-color)`,
    textTransform: `var(${themeVarRoot}--heading-x-large--text-transform)`,
  },
  headingLarge: {
    fontFamily: `var(${themeVarRoot}--heading-large--font-family)`,
    fontSize: `var(${themeVarRoot}--heading-large--font-size)`,
    fontStretch: `var(${themeVarRoot}--heading-large--font-stretch)`,
    fontWeight: `var(${themeVarRoot}--heading-large--font-weight)`,
    letterSpacing: `var(${themeVarRoot}--heading-large--letter-spacing)`,
    lineHeight: `var(${themeVarRoot}--heading-large--line-height)`,
    textColor: `var(${themeVarRoot}--heading-large--text-color)`,
    textTransform: `var(${themeVarRoot}--heading-large--text-transform)`,
  },
  headingMedium: {
    fontFamily: `var(${themeVarRoot}--heading-medium--font-family)`,
    fontSize: `var(${themeVarRoot}--heading-medium--font-size)`,
    fontStretch: `var(${themeVarRoot}--heading-medium--font-stretch)`,
    fontWeight: `var(${themeVarRoot}--heading-medium--font-weight)`,
    letterSpacing: `var(${themeVarRoot}--heading-medium--letter-spacing)`,
    lineHeight: `var(${themeVarRoot}--heading-medium--line-height)`,
    textColor: `var(${themeVarRoot}--heading-medium--text-color)`,
    textTransform: `var(${themeVarRoot}--heading-medium--text-transform)`,
  },
  headingSmall: {
    fontFamily: `var(${themeVarRoot}--heading-small--font-family)`,
    fontSize: `var(${themeVarRoot}--heading-small--font-size)`,
    fontStretch: `var(${themeVarRoot}--heading-small--font-stretch)`,
    fontWeight: `var(${themeVarRoot}--heading-small--font-weight)`,
    letterSpacing: `var(${themeVarRoot}--heading-small--letter-spacing)`,
    lineHeight: `var(${themeVarRoot}--heading-small--line-height)`,
    textColor: `var(${themeVarRoot}--heading-small--text-color)`,
    textTransform: `var(${themeVarRoot}--heading-small--text-transform)`,
  },
  headingXSmall: {
    fontFamily: `var(${themeVarRoot}--heading-x-small--font-family)`,
    fontSize: `var(${themeVarRoot}--heading-x-small--font-size)`,
    fontStretch: `var(${themeVarRoot}--heading-x-small--font-stretch)`,
    fontWeight: `var(${themeVarRoot}--heading-x-small--font-weight)`,
    letterSpacing: `var(${themeVarRoot}--heading-x-small--letter-spacing)`,
    lineHeight: `var(${themeVarRoot}--heading-x-small--line-height)`,
    textColor: `var(${themeVarRoot}--heading-x-small--text-color)`,
    textTransform: `var(${themeVarRoot}--heading-x-small--text-transform)`,
  },
  link: {
    fontStretch: `var(${themeVarRoot}--link--font-stretch)`,
    fontWeight: `var(${themeVarRoot}--link--font-weight)`,
    hoverColor: `var(${themeVarRoot}--link--hover-color)`,
    textColor: `var(${themeVarRoot}--link--text-color)`,
    textTransform: `var(${themeVarRoot}--link--text-transform)`,
    underline: `var(${themeVarRoot}--link--underline)`,
  },
  pageTitle: {
    fontFamily: `var(${themeVarRoot}--page-title--font-family)`,
    fontSize: `var(${themeVarRoot}--page-title--font-size)`,
    fontStretch: `var(${themeVarRoot}--page-title--font-stretch)`,
    fontWeight: `var(${themeVarRoot}--page-title--font-weight)`,
    letterSpacing: `var(${themeVarRoot}--page-title--letter-spacing)`,
    lineHeight: `var(${themeVarRoot}--page-title--line-height)`,
    textColor: `var(${themeVarRoot}--page-title--text-color)`,
    textTransform: `var(${themeVarRoot}--page-title--text-transform)`,
  },
  pageDescription: {
    fontFamily: `var(${themeVarRoot}--page-description--font-family)`,
    fontSize: `var(${themeVarRoot}--page-description--font-size)`,
    fontStretch: `var(${themeVarRoot}--page-description--font-stretch)`,
    fontWeight: `var(${themeVarRoot}--page-description--font-weight)`,
    letterSpacing: `var(${themeVarRoot}--page-description--letter-spacing)`,
    lineHeight: `var(${themeVarRoot}--page-description--line-height)`,
    textColor: `var(${themeVarRoot}--page-description--text-color)`,
    textTransform: `var(${themeVarRoot}--page-description--text-transform)`,
  },
  primaryNav: {
    accentColor: `var(${themeVarRoot}--primary-nav--accent-color)`,
    backgroundColor: `var(${themeVarRoot}--primary-nav--background-color)`,
    fontFamily: `var(${themeVarRoot}--primary-nav--font-family)`,
    fontSize: `var(${themeVarRoot}--primary-nav--font-size)`,
    fontStretch: `var(${themeVarRoot}--primary-nav--font-stretch)`,
    fontWeight: `var(${themeVarRoot}--primary-nav--font-weight)`,
    hoverColor: `var(${themeVarRoot}--primary-nav--hover-color)`,
    letterSpacing: `var(${themeVarRoot}--primary-nav--letter-spacing)`,
    lineHeight: `var(${themeVarRoot}--primary-nav--line-height)`,
    textColor: `var(${themeVarRoot}--primary-nav--text-color)`,
    textTransform: `var(${themeVarRoot}--primary-nav--text-transform)`,
  },
  secondaryNav: {
    accentColor: `var(${themeVarRoot}--secondary-nav--accent-color)`,
    backgroundColor: `var(${themeVarRoot}--secondary-nav--background-color)`,
    fontFamily: `var(${themeVarRoot}--secondary-nav--font-family)`,
    fontSize: `var(${themeVarRoot}--secondary-nav--font-size)`,
    fontStretch: `var(${themeVarRoot}--secondary-nav--font-stretch)`,
    fontWeight: `var(${themeVarRoot}--secondary-nav--font-weight)`,
    letterSpacing: `var(${themeVarRoot}--secondary-nav--letter-spacing)`,
    lineHeight: `var(${themeVarRoot}--secondary-nav--line-height)`,
    textColor: `var(${themeVarRoot}--secondary-nav--text-color)`,
    textTransform: `var(${themeVarRoot}--secondary-nav--text-transform)`,
  },
  tabs: {
    accentColor: `var(${themeVarRoot}--tabs--accent-color)`,
    fontFamily: `var(${themeVarRoot}--tabs--font-family)`,
    fontSize: `var(${themeVarRoot}--tabs--font-size)`,
    fontStretch: `var(${themeVarRoot}--tabs--font-stretch)`,
    fontWeight: `var(${themeVarRoot}--tabs--font-weight)`,
    letterSpacing: `var(${themeVarRoot}--tabs--letter-spacing)`,
    lineHeight: `var(${themeVarRoot}--tabs--line-height)`,
    textColor: `var(${themeVarRoot}--tabs--text-color)`,
    textTransform: `var(${themeVarRoot}--tabs--text-transform)`,
  },
};
