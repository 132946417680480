import { lazy } from 'react';
import { useAppStateMatches } from '@/core';
import { useActiveTopNavItem } from '../hooks';

const AddNewTrigger = lazy(
  () => import('@/domains/navs/components/add-new/add-new-trigger'),
);

export const SecondaryNavFooter = () => {
  const canEdit = useAppStateMatches('site.loaded.appClient.viewable.editable');
  const activeTopNavItem = useActiveTopNavItem();

  return (
    canEdit && (
      <AddNewTrigger
        menuPlacement="right"
        parentId={activeTopNavItem?.id}
        testId="add-entity--trigger--sidebar"
      />
    )
  );
};
